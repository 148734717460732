import { useStore } from "zustand"
import { createStore } from "zustand/vanilla"

const paddleStore = createStore((set, get) => {
    
    const store = {
        env: undefined,
        authToken: undefined,
        products: undefined,
        init: async () => {
            try {
                const res = await fetch(import.meta.env.VITE_API_BASE + "/store/paddle")
                const paddleConfig = await res.json()
                set({
                    env: paddleConfig.paddle_env,
                    authToken: paddleConfig.auth_token,
                    products: paddleConfig.products
                })
            } catch (e) {
                if (e.name === "SyntaxError") return {}
            }
        }
    }

    return store
})

const usePaddleStore = (selector) => useStore(paddleStore, selector)
export { paddleStore, usePaddleStore }
