import * as React from "react"
import Box from "@mui/material/Box"
import Button from "@mui/material/Button"
import Grid from "@mui/material/Grid2"
import Typography from "@mui/material/Typography"
import { Link as RouterLink } from "react-router"
import HomepageOutline from "./HomepageOutline.jsx"

import {
    BellRinging,
    BracketsCurly,
    CloudSlash,
    FileVideo,
    TagSimple,
    PersonSimpleSki,
    Note,
    ReceiptX,
    ShieldCheck,
    Waveform,
    UserSound,
    Translate,
    Rabbit
} from "@phosphor-icons/react"

export default function Homepage({ theme }) {

    const [wideScreen, setWideScreen] = React.useState(false)
    const checkWindowSize = () => {
        const isWideScreen = (window.innerWidth > 600)
        setWideScreen(isWideScreen)
    }
    window.addEventListener("resize", checkWindowSize)
    React.useEffect(checkWindowSize, [])

    return (
        <HomepageOutline theme={theme} wideScreen={wideScreen}>
            <Typography variant="h4" sx={{ mb: 2 }}>
                Transcribe and organize piles of recorded interviews.
            </Typography>
            <Typography variant="h6" sx={{ mb: 2 }}>
                Tag important moments with timestamped notes.
                Build a topic index to connect the dots across all your sources.
                Then add fast, accurate, automatic transcription from $0.50 per hour &mdash; prepaid, without troublesome subscriptions.
            </Typography>

            <Box
                component="img"
                sx={{
                    width: '100%'
                }}
                alt="Screenshot"
                src="/img/screenshot.png"
            />

            <Box sx={{ mt: 8, mb: 12, height: "64px", overflow: "hidden", display: "flex", justifyContent: "center"}}>
                { wideScreen &&
                    <Button
                        disableElevation
                        component={RouterLink}
                        to={import.meta.env.VITE_PWA_START_URL}
                        size="large"
                        variant="contained" 
                        startIcon={<BellRinging size={32} />}
                        sx={{ mr: 4, px: 4, border: "solid 2px white", borderRadius: "8px" }}

                    >
                        Open In-Browser
                    </Button>
                }
                <ms-store-badge
                    productid="9plcvv5z9gr1"
                    productname="Ding Notes"
                    window-mode="direct"
                    theme="dark"
                    language="en"
                    animation="off">
                </ms-store-badge>
            </Box>

            <Typography variant="h5">
                <b>Smart tools to manually review audio and video.</b>
            </Typography>
            <Typography variant="h6">
                <Grid container spacing={2} sx={{ mt: 2 }}>
                    <Grid size={{ xs: 12, sm: 4 }} sx={{ mb: 2 }}>
                        <Typography>
                            <Waveform size={32} />
                        </Typography>
                        <Typography variant="button">Waveform & transcript</Typography>
                        <br />
                        Quickly navigate across your recordings
                    </Grid>
                    <Grid size={{ xs: 12, sm: 4 }} sx={{ mb: 2 }}>
                        <Typography>
                            <Note size={32} />
                        </Typography>
                        <Typography variant="button">Time-coded notes</Typography>
                        <br />
                        Immediately jump back to crucial moments
                    </Grid>
                    <Grid size={{ xs: 12, sm: 4 }} sx={{ mb: 2 }}>
                        <Typography>
                            <TagSimple size={32} />
                        </Typography>
                        <Typography variant="button">Tags & topic index</Typography>
                        <br />
                        Synthesise themes over all your material
                    </Grid>
                    <Grid size={{ xs: 12, sm: 4 }} sx={{ mb: 2 }}>
                        <Typography>
                            <FileVideo size={32} />
                        </Typography>
                        <Typography variant="button">mp3, m4a, mov, mp4, wav...</Typography>
                        <br />
                        Supports recordings in common audio and video file formats
                    </Grid>
                    <Grid size={{ xs: 12, sm: 4 }} sx={{ mb: 2 }}>
                        <Typography>
                            <CloudSlash size={32} />
                        </Typography>
                        <Typography variant="button">Local Storage & Offline Mode</Typography>
                        <br />
                        Your data stays on your device for privacy, security & speed
                    </Grid>
                    <Grid size={{ xs: 12, sm: 4 }} sx={{ mb: 2 }}>
                        <Typography>
                            <BracketsCurly size={32} />
                        </Typography>
                        <Typography variant="button">It computes</Typography>
                        <br />
                        Simple JSON file format. Roll your own analyses with tools like jq.
                    </Grid>
                </Grid>
            </Typography>

            <Typography variant="h5" sx={{ mt: 12 }}>
                <b>Transcription without subscription.</b>
            </Typography>
            <Typography variant="h6">
                <Grid container spacing={2} sx={{ mt: 2 }}>

                    <Grid size={{ xs: 12, sm: 4 }} sx={{ mb: 2 }}>
                        <Typography>
                            <Rabbit size={32} />
                        </Typography>
                        <Typography variant="button">Super Quick</Typography>
                        <br />
                        Transcripts completed before your kettle boils
                    </Grid>

                    <Grid size={{ xs: 12, sm: 4 }} sx={{ mb: 2 }}>
                        <Typography>
                            <UserSound size={32} />
                        </Typography>
                        <Typography variant="button">Speaker Identification</Typography>
                        <br />
                        Automatically identifies and labels individual voices
                    </Grid>

                    <Grid size={{ xs: 12, sm: 4 }} sx={{ mb: 2 }}>
                        <Typography>
                            <Translate size={32} />
                        </Typography>
                        <Typography variant="button">Language Support</Typography>
                        <br />
                        Works with 13 languages
                    </Grid>
                    <Grid size={{ xs: 12, sm: 4 }} sx={{ mb: 2 }}>
                        <Typography>
                            <ReceiptX size={32} />
                        </Typography>
                        <Typography variant="button">No sign-up required</Typography>
                        <br />
                        Just pre-pay by the minute. We'll send you an access code to use on any
                        device.
                    </Grid>
                    <Grid size={{ xs: 12, sm: 4 }} sx={{ mb: 2 }}>
                        <Typography>
                            <PersonSimpleSki size={32} />
                        </Typography>
                        <Typography variant="button">No monthly limits</Typography>
                        <br />
                        Transcribe as much as you like, whenever you like.
                    </Grid>
                    <Grid size={{ xs: 12, sm: 4 }} sx={{ mb: 2 }}>
                        <Typography>
                            <ShieldCheck size={32} />
                        </Typography>
                        <Typography variant="button">No data harvesting</Typography>
                        <br />
                        We delete your media and transcription from our servers soon after returning
                        it to you.
                    </Grid>
                </Grid>
            </Typography>

            <Typography variant="h5" sx={{ mt: 8, pb: 8 }}>
                For journalists, authors, researchers, oral historians and students.
            </Typography>

            <Typography variant="caption" sx={{ mb: 2 }}>
                Prices listed in USD; excludes tax. Transcription at $0.50/hour requires purchase of $100 credit,
                in conjunction with "express" level of service.
            </Typography>

        </HomepageOutline>
    )
}
