import Typography from "@mui/material/Typography"

export default [
    {
        h: "What's in a name?",
        qa_list: [
            {
                q: "Why is it called Ding Notes?",
                a: (
                    <>
                        <Typography>
                            You work hard and probably deserve a delicious cup of tea. Some kettles
                            whistle when they boil. Others have a bell that goes "ding!".
                        </Typography>
                        <Typography sx={{ pt: 1 }}>
                            Our automated transcription service is so fast that once your file is
                            uploaded, we'll probably have it ready for you before your kettle goes
                            "ding". This way, you can enjoy reading a highly accurate transcript
                            with a lovely hot beverage in hand.
                        </Typography>
                    </>
                ),
            },
        ],
    },
    {
        h: "Getting Started",
        qa_list: [
            {
                q: "Which web browsers are supported?",
                a: (
                    <>
                        <Typography>
                            Ding Notes currently supports Google Chrome, Microsoft Edge, and other
                            Chromium-based browsers. Unfortunately we don't support Safari or
                            Firefox.
                        </Typography>
                        <Typography sx={{ pt: 1 }}>
                            This is because Ding Notes reads and saves files directly on your device, and
                            not all browsers support this level of access.
                        </Typography>
                    </>
                ),
            },
            {
                q: "Why do I have to choose a folder to open?",
                a: (
                    <Typography>
                        Ding Notes is designed to work with collections of recordings, taking a
                        folder-level view so you can easily jump between multiple files.
                        For example, you might have dozens of recordings as part of a
                        research project.
                    </Typography>
                ),
            },
            {
                q: "Why is Ding Notes asking to view and edit my files?",
                a: (
                    <Typography>
                        Ding Notes reads and saves files directly on your device, so you will be asked
                        to grant this permission for the folders you choose to open.
                    </Typography>
                ),
            },
        ],
    },
    {
        h: "Storage & Privacy",
        qa_list: [
            {
                q: "Where does Ding Notes store my data?",
                a: (
                    <>
                        <Typography>
                            Ding Notes stores notes and transcripts on your device, within the folder that
                            contains your recorded material. We don't want the responsibility of
                            looking after your sensitive data!
                        </Typography>
                        <Typography sx={{ pt: 1 }}>
                            Your media files and transcripts are temporarily stored on Ding Notes servers for
                            processing when using the automated transcription services.
                        </Typography>
                    </>
                ),
            },
            {
                q: "When and how does Ding Notes handle my data on its servers?",
                a: (
                    <>
                        <Typography>
                            Your files are only ever uploaded to Ding Notes servers when you choose to
                            use the automated transcription service.
                        </Typography>
                        <Typography sx={{ pt: 1 }}>
                            We keep your files (and the transcriptions made from them) for a short period
                            of time, just in case technical troubleshooting is necessary. We delete files
                            and transcripts from our systems 24 hours after the transcript appears in your
                            Ding Notes app. We delete uncollected transcripts after 7 days.
                        </Typography>
                        <Typography sx={{ pt: 1 }}>
                            Our payments partner, Paddle, securely stores information related to
                            purchases and billing. You can read about their security measures&nbsp;
                            <a href="https://security.paddle.com/">here</a>.
                        </Typography>
                    </>
                ),
            },
            {
                q: "Am I responsible for backing up the files I use with Ding Notes?",
                a: <Typography>Yes.</Typography>,
            },
            {
                q: "Can I move or rename my recordings?",
                a: (
                    <Typography>
                        No. Ding Notes stores notes and transcripts with reference to the filename of the
                        recording they are associated with. We plan to add move and rename
                        functionality in the future.
                    </Typography>
                ),
            },
        ],
    },
    {
        h: "Collaboration & Sharing",
        qa_list: [
            {
                q: "Can I share or collaborate with others with Ding Notes?",
                a: (
                    <Typography>
                        Ding Notes doesn't have any collaboration functionality yet. If you need to work
                        with other people, you can share your Ding Notes folders using a cloud storage app
                        like Dropbox or Google Drive. However, only one person at a time should
                        access a Ding Notes folder, as concurrent editing by multiple users may result in
                        data loss.
                    </Typography>
                ),
            },
        ],
    },
    {
        h: "Transcription Service & Billing",
        qa_list: [
            {
                q: "How do I pay for transcription?",
                a: (
                    <Typography>
                        Ding Notes offers a prepaid, per-minute AI transcription service. Transcription
                        credits can be purchased in the app. Upon payment, Ding Notes will issue you a
                        credit code linked to the credits you paid for. The credit code will be
                        automatically added to the app. An invoice, and a copy of the credit code,
                        will be emailed to you for your records.
                    </Typography>
                ),
            },
            {
                q: "Can I share credit codes with other people or devices?",
                a: (
                    <Typography>
                        Yes. You may share credit codes (e.g. with colleagues), or use them on
                        multiple devices. Just remember that anyone you give the credit codes to
                        will have unlimited access to the value associated with them.
                    </Typography>
                ),
            },
            {
                q: "Do credit codes expire?",
                a: (
                    <Typography>
                        Yes. Credit codes expire one year after last use. Every time you order a
                        transcription, it resets the expiry clock on all credit codes loaded on your
                        device.
                    </Typography>
                ),
            },
            {
                q: "What is Paddle? Why was my card charged by them?",
                a: (
                    <>
                        <Typography>
                            Ding doesn't handle payments directly; we use a payments partner called
                            Paddle. They resell our products and handle the tedious side of business
                            for us, such as taxes, compliance, and payment systems security.
                        </Typography>
                        <Typography sx={{ pt: 1 }}>
                            Because of this, you will receive a receipt from Paddle and the
                            transaction will appear on your statement as "PADDLE.NET* DING".
                        </Typography>
                    </>
                ),
            },
            {
                q: "Do you offer refunds?",
                a: (
                    <>
                        <Typography>
                            We don't offer refunds for unused credits or unsatisfactory results.
                            Please remember that automatically generated transcriptions will not be
                            completely accurate.
                        </Typography>
                        <Typography sx={{ pt: 1 }}>
                            We will only consider refunds in exceptional cases.
                        </Typography>
                    </>
                ),
            },
        ],
    },
]
