import * as React from "react"

import Button from "@mui/material/Button"
import Box from "@mui/material/Box"
import Container from "@mui/material/Container"
import CssBaseline from "@mui/material/CssBaseline"
import Drawer from "@mui/material/Drawer"
import Grid from "@mui/material/Grid2"
import Link from "@mui/material/Link"
import { grey } from "@mui/material/colors"
import ThemeProvider from "@mui/system/ThemeProvider"
import Typography from "@mui/material/Typography"
import { Link as RouterLink } from "react-router"
import { List, X } from "@phosphor-icons/react"

export default function HomepageOutline({ theme, children, wideScreen }) {
    
    const [open, setOpen] = React.useState(false)
    React.useEffect(() => { if (wideScreen) setOpen(false) }, [wideScreen])

    const navLinks = (fontSize, spacing) =>
        <>
            <Link variant="button" fontSize={fontSize} underline="hover" href="/about" sx={{ mr: wideScreen ? 4 : 0, mb: spacing }}>
                About
            </Link>
            <Link variant="button" fontSize={fontSize} underline="hover" href="/pricing" sx={{ mr: wideScreen ? 4 : 0, mb: spacing }}>
                Pricing
            </Link>
            <Link variant="button" fontSize={fontSize} underline="hover" href="/faq" sx={{ mr: wideScreen ? 4 : 0, mb: spacing }}>
                FAQ
            </Link>
            {
                !wideScreen &&
                <Typography sx={{ mt: 2, p: 2, color: grey[600], textAlign: 'center' }}>
                    Get started by opening <b>dingnotes.com</b><br/>
                    on your desktop or laptop.
                </Typography>
            }
        </>


    return (
        <React.StrictMode>
            <ThemeProvider theme={theme}>
                <CssBaseline />
                <Container maxWidth="md" sx={{ mb: 16, mt: 0, color: grey[800] }}>
                    <Grid container spacing={2} sx={{ mt: 4, mb: 8 }}>
                        <Grid>
                            <Link variant="h4" color="textPrimary" underline="none" href="/">
                                <b>Ding Notes</b>
                            </Link>
                        </Grid>

                        <Drawer
                            open={open}
                            onClose={() => setOpen(false)}
                            transitionDuration={
                                {enter: theme.transitions.duration.enteringScreen,
                                exit: 0}
                            }
                        >
                            <Box sx={{ width: window.innerWidth }} role="presentation" onClick={() => setOpen(false)}>
                                <Grid sx={{ p: 2, mt: 2, display: "flex", flexDirection: "row" }}>
                                    <Grid>
                                        <Link variant="h4" color="textPrimary" underline="none" href="/">
                                            <b>Ding Notes</b>
                                        </Link>
                                    </Grid>
                                    <Grid sx={{ display:"flex", flexGrow: 1, alignItems:"center", justifyContent: "end"}}>
                                        <X weight="bold" size={32} color={grey[600]} onClick={ () => setOpen(false) }/>
                                    </Grid>
                                </Grid>

                                <Grid sx={{ p: 2, display:"flex", flexDirection:"column", alignItems: "center"}}>
                                    {navLinks(24, 2)}
                                </Grid>
                            </Box>
                        </Drawer>

                        {
                            wideScreen ?
                            <>
                                <Grid display="flex" size="grow" alignItems="center" justifyContent="end">
                                    {navLinks()}
                                </Grid>
                                <Grid style={{ alignContent: "center" }}>
                                    <Button
                                        variant="contained"
                                        component={RouterLink}
                                        to={import.meta.env.VITE_PWA_START_URL}
                                        disableElevation
                                    >
                                        Open the App
                                    </Button>
                                </Grid>
                            </>

                            :
                            <>
                                <Grid display="flex" size="grow" alignItems="center" justifyContent="end">
                                    <List weight="bold" size={32} onClick={ () => setOpen(true)} />
                                </Grid>
                            </>
                        }
                    </Grid>

                    {children}

                    <Grid container spacing={2} sx={{ pt: 8, pb: 4 }}>
                        <Link
                            variant="button"
                            underline="hover"
                            href="/terms"
                            sx={{ mr: 4, color: grey[500] }}
                        >
                            Terms
                        </Link>
                        <Link
                            variant="button"
                            underline="hover"
                            href="/privacy"
                            sx={{ mr: 4, color: grey[500] }}
                        >
                            Privacy
                        </Link>
                    </Grid>
                </Container>
            </ThemeProvider>
        </React.StrictMode>
    )
}
