import * as Sentry from "@sentry/react"
import { createTheme } from "@mui/material/styles"
import ReactDOM from "react-dom/client"
import { RouterProvider, createBrowserRouter, Outlet } from "react-router"
import "./titillium.css"
import ErrorElement from "./components/ErrorElement.jsx"
import AboutPage from "./components/AboutPage.jsx"
import HelpPage from "./components/HelpPage.jsx"
import Homepage from "./components/Homepage.jsx"
import PricingPage from "./components/PricingPage.jsx"
import PrivacyPage from "./components/PrivacyPage.jsx"
import TermsPage from "./components/TermsPage.jsx"

if (import.meta.env.PROD) {
    Sentry.init({
        dsn: "https://16ccf29d46bce5ad9c643538867192a6@o4508617071919104.ingest.de.sentry.io/4508617148268624",
        environment: process.env.NODE_ENV,
        transport: Sentry.makeBrowserOfflineTransport(Sentry.makeFetchTransport),
        integrations: [Sentry.browserTracingIntegration(), Sentry.replayIntegration()],
        // Tracing
        tracesSampleRate: 1.0, //  Capture 100% of the transactions
        // Set 'tracePropagationTargets' to control for which URLs distributed tracing should be enabled
        tracePropagationTargets: ["localhost", /^https:\/\/dingnotes\.com\//],
        // Session Replay
        replaysSessionSampleRate: 0.1, // This sets the sample rate at 10%. You may want to change it to 100% while in development and then sample at a lower rate in production.
        replaysOnErrorSampleRate: 1.0, // If you're not already sampling the entire session, change the sample rate to 100% when sampling sessions where errors occur.
        beforeSend: (event) => {
            if (event.transaction?.startsWith("/app/explorer")) {
                event.transaction = "/app/explorer" // Scrub the filename
                // biome-ignore lint/performance/noDelete: <explanation>
                delete event.request
            }
            return event
        },
    })
}

const theme = createTheme({
    typography: {
        fontFamily: [
            // 'ui-sans-serif', 'system-ui',
            "Asap",
            // 'Titillium Web',
            "-apple-system",
            "BlinkMacSystemFont",
            '"Segoe UI"',
            "Roboto",
            '"Helvetica Neue"',
            "Arial",
            "sans-serif",
            '"Apple Color Emoji"',
            '"Segoe UI Emoji"',
            '"Segoe UI Symbol"',
        ].join(","),
    },
})

const router = createBrowserRouter(
    [
        {
            element: (
                <>
                    <Outlet />
                </>
            ),
            errorElement: <ErrorElement theme={theme} />,
            children: [
                {
                    path: "/",
                    element: <Homepage theme={theme} />,
                },
                {
                    path: "/about",
                    element: <AboutPage theme={theme} />,
                },
                {
                    path: "/faq",
                    element: <HelpPage theme={theme} />,
                },
                {
                    path: "/pricing",
                    element: <PricingPage theme={theme} />,
                },
                {
                    path: "/terms",
                    element: <TermsPage theme={theme} />,
                },
                {
                    path: "/privacy",
                    element: <PrivacyPage theme={theme} />,
                },
            ],
        },
    ],
    {},
)

ReactDOM.createRoot(document.getElementById("root")).render(<RouterProvider router={router} />)
